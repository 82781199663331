import {useEffect, useState} from "react";
import useSite from "src/core/sites/hooks/useSite";
import {topPicks} from "src/core/api/products";
import Product from "src/core/common/models/product";
import {populateRelations} from "src/core/api/utils";
import useAuthentication from "src/core/authentication/hooks/useAuthentication";
import pickBy from "lodash/pickBy";
import identity from "lodash/identity";
import useFeatureToggles from "src/core/common/hooks/useFeatureToggles";

async function getUserTopPicks(params) {
  return await topPicks(params).then(response =>
    response.data.map(
      element => new Product(populateRelations(element, response.included))
    )
  );
}

export default function useUserTopPicks(filters) {
  const [products, setProducts] = useState([]);
  const [meta, setMeta] = useState({loading: false});
  const site = useSite();
  const [, authApi] = useAuthentication();
  const isUserLoggedIn = authApi.isLoggedIn();
  const toggles = useFeatureToggles();
  const showRecommendedList = toggles.showRecommendedList();

  useEffect(() => {
    (async () => {
      if (!showRecommendedList) return;
      try {
        setMeta({loading: true});

        const products = await getUserTopPicks({
          limit: 6,
          offset: 0,
          ...pickBy(filters.serialize(), identity),
        });
        setProducts(products);
        setMeta({loading: false});
      } catch (error) {
        console.log(error);
        setMeta({loading: false, error});
      }
    })();
  }, [showRecommendedList, isUserLoggedIn, filters.toString()]);

  return {
    products,
    meta,
    site,
    isUserLoggedIn,
    showRecommendations: meta.loading || products?.length > 0,
  };
}
